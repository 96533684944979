import { createStore } from 'vuex';
import CCVModule from './modules/CCVModule';
import KlikoModule from './modules/KlikoModule';
import SitekioskModule from './modules/SitekioskModule';
const getDefaultState = () => {
    return {};
}

const Store = createStore({
    modules: {
        ccv: CCVModule,
        kliko: KlikoModule,
        sitekiosk: SitekioskModule
    },
    state: getDefaultState(),
    mutations: {
        RESET_STATE(state) {
            Object.assign(state, getDefaultState());
            console.log('Global:RESET_STATE', state);
        }
    },
    actions: {
        resetState( { state}) {
            let sitekiosk = state.sitekiosk;
            this.replaceState({ccv: {}, kliko: {}, sitekiosk: sitekiosk});
        }
    }
});

export default Store
    