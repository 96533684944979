import router from './../Router.js';


const getDefaultState = () => {
    return {
        sitekiosk_model: null,
        nfccode: "",
    };
}

const SitekioskModule = {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        SET_SITEKIOSK_MODEL(state, response) {
            console.log('Sitekiosk:SET_SITEKIOSK_MODEL', response);
            state.sitekiosk_model = response;
        },
        SET_NFCCODE(state, response) {
            console.log('Sitekiosk:SET_NFCCODE', response);
            state.nfccode = response;
        },

    },
    actions: {
        async loadModel( {commit, state, dispatch}){
            //eslint-disable-next-line 
            if (state.sitekiosk_model === null || state.sitekiosk_model === undefined) {
                try {
                    //eslint-disable-next-line 
                    (new Function(_siteKiosk.getSiteKioskObjectModelCode()))();
                    console.log('SiteKioskModel start');
                    //eslint-disable-next-line 
                    commit('SET_SITEKIOSK_MODEL', siteKiosk);
                    state.sitekiosk_model.ready(() => {
                        state.sitekiosk_model.onEmulatedDeviceData((deviceId, keyCode, character) => {
                            dispatch('onEmulatedDeviceData', {character});
                        });
                    });

                } catch (err) {
                    console.log(err);
                    router.push({name: 'Error', params: {status_code: 'critical failure', error_code: '-', error_message: 'Sitekiosk model could not be started'}});
                }
        }
        },

        onEmulatedDeviceData( {state, commit}, {character}){
            console.log('onEmulatedDeviceData', character);

            clearTimeout(state.clearTimer);
            state.clearTimer = setTimeout(() => {
                commit('SET_NFCCODE', "");
            }, 1000);
            commit('SET_NFCCODE', state.nfccode + character);
            if (state.nfccode.length > 0 && state.nfccode.indexOf('\r') > -1) {
                commit('kliko/SET_SERIALNUMBER', state.nfccode, {root: true});
                commit('SET_NFCCODE', "");
        }
        }
    }
};

export default SitekioskModule
    