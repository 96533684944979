<template>
    <div class="wrapper">
        <p class="page-title">Deze kiosk is buiten gebruik.</p>
        <p class="page-subtitle">Er is geen internet verbinding mogelijk.</p>
    </div>
</template>

<script>

    export default {
        name: 'ErrorOffline',
        props: {
        }, 
        mounted() {
            window.addEventListener('online', this.onInternetOnline);
        },
        unmounted() {
            window.removeEventListener('online', this.onInternetOnline);
        },
        methods: {
            onInternetOnline:function() {
                this.$router.push({name: 'Home'});
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .error-line {
        font-family:'ScalaSansPro'; 
        font-size :40px;
        color:#FFFFFF;
        text-align:center;
        margin:0;
        margin-top:20px;
    }
</style>
