import KlikoService from './../services/KlikoService';
import router from './../Router.js';

const getDefaultState = () => {
    return {
    };
}

const KlikoModule = {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        order_status_code: state => {
            if (state.order_status && state.order_status.valid) {
                return 'success';
            } else {
                return 'error';
            }

        },
        kliko_account_balance: state => {
            return state.kliko_account ? state.kliko_account.balance : 0;
        }
    },
    mutations: {
        SET_KLIKO_ACCOUNT(state, response) {
            console.log('Kliko:SET_KLIKO_ACCOUNT', response);
            state.kliko_account = response;
        },
        SET_SERIALNUMBER(state, response) {
            console.log('Kliko:SET_SERIALNUMBER', response);
            state.serialnumber = response;
        },
        SET_UPGRADE_OPTION(state, response) {
            console.log('Kliko:SET_UPGRADE_OPTION', response);
            state.upgrade_option = response;
        },
        SET_ORDER_STATUS(state, response) {
            console.log('Kliko:SET_ORDER_STATUS', response);
            state.order_status = response;
        },
    },
    actions: {
        async loadCard( {commit}, serialnumber){
            commit("SET_SERIALNUMBER", serialnumber);
            try {
                const kliko_response = await KlikoService.getBalance(serialnumber);
                commit("SET_KLIKO_ACCOUNT", kliko_response.data);
            } catch (error) {
                if (error.response) {
                    router.push({name: 'Error', params: {status_code: error.response.status, error_code: error.response.data.error, error_message: error.response.data.message}});
                } else {
                    router.push({name: 'Error', params: {status_code: error.status, error_code: 'Onbekend', error_message: 'Kliko onbereikbaar'}});
                }
        }
        },
        async updateBalance( {commit, state, rootState}){
            try {
                const kliko_response = await KlikoService.updateBalance(rootState.ccv.transaction.reference, state.serialnumber, state.upgrade_option.euro);
                commit("SET_ORDER_STATUS", kliko_response.data);
            } catch (error) {
                if (error.response) {
                    router.push({name: 'Error', params: {status_code: error.response.status, error_code: error.response.data.error, error_message: error.response.data.message}});
                } else {
                    router.push({name: 'Error', params: {status_code: error.status, error_code: 'Onbekend', error_message: 'Kliko onbereikbaar'}});
                }
        }
        },
//        async sendReceipt( { state}){
//            await KlikoService.sendReceipt(
//                    state.transaction.reference,
//                    state.serialnumber,
//                    state.upgrade_option.balance,
//                    state.transaction_receipt_email,
//                    state.transaction_status.details.customerReceipt
//                    );
//        }
    }
};

export default KlikoModule
    