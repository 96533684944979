<template>
    <div class="wrapper">
        <p class="page-title">Er is een probleem opgetreden.</p>
        <p class="error-line">Status:   {{status_code ? status_code : 'Onbekend'}}</p>
        <p class="error-line">Code:     {{error_code ? error_code : 'Onbekend'}}</p>
        <p class="error-line">Melding:  {{error_message ? error_message : 'Onbekend'}}</p>
    </div>
</template>

<script>

    export default {
        name: 'Error',
        props: {
            error_code: String,
            error_message: String,
            status_code: String
        },
        beforeMount() {
            setTimeout(() => {
                this.$router.push({name: "Home"});
            }, 10000)
        },
        beforeUnmount() {

        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .error-line {
        font-family:'ScalaSansPro'; 
        font-size :40px;
        color:#FFFFFF;
        text-align:center;
        margin:0;
        margin-top:20px;
    }
</style>
