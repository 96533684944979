<template>
    <div class="wrapper">     
        <p class="page-title">Wilt u een bonnetje?</p>
        <p class="page-subtitle">Vul uw email-adres in<br>als u een digitaal bonnetje wilt.</p>
        <div class="email-form">
            <div :class="['input-group', isEmailValid()]">
                <!--<span class="input-group-addon" id="basic-addon1"><span class="fa fa-envelope"></span></span>-->
                <input type="email" class="form-control" placeholder="e-mailadres" v-model="email" />
            </div>
            <button :class="['button-green','button-submit',isEmailValid()]" v-on:click="onSubmitClick">Doorgaan</button>
            <!--<button class="button-green button-noreceipt" v-on:click="onAbortClick">Nee, bedankt</button>-->
        </div>

        <button class="button-yellow button-abort" v-on:click="onAbortClick">Afbreken</button>

    </div>
</template>

<script>
    export default {
        name: 'EmailForm',
        data: function () {
            return {
                email: '',
                reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
            }
        },
        mounted() {
        },
        unmounted() {
        },
        methods: {
            isEmailValid: function () {
                return (this.email == "") ? "" : (this.reg.test(this.email)) ? 'has-success' : 'has-error';
            },
            onSubmitClick: function () {
                if (this.reg.test(this.email) || this.email == "") {
                    this.$store.commit('ccv/SET_TRANSACTION_RECEIPT_EMAIL', this.email);
                    this.$router.push({name: 'Payment'});
                }
            },
            onAbortClick: function () {
                this.$router.push({name: 'Options'});
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .wrapper :first-child {
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .email-form{
        margin:40px 0px 40px 0px;
        width:500px;
    }
    .input-group{
        padding:0px;
        border:5px solid white;
        background-color:white;
    }
    .input-group input{
        font-family:'ScalaSansPro';
        font-size: 40px;
        width:100%;
        padding:20px;
        outline:none;
        border:none;
    }
    .button-submit{
        margin-top:20px;
        width:100%;
    }
    .button-submit.has-succes{
        opacity:1;
    }
    .button-submit.has-error{
        opacity:0.5;
    }
    .input-group.has-success{
        border-color: #63A160;
    }
    .input-group.has-error{
        border-color: #FF2A00;
    }
</style>
