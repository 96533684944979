<template>
    <div class="upgrade-option-item">
        <p class="points-text">{{ this.option.balance }} {{this.option.balance>1?'punten':'punt'}} </p>
        <p class="euro-text"> (€{{ this.option.euro }})</p>
    </div>
</template>

<script>
    export default {
        props: {
            option: {type: Object, required: true}

        },
        mounted() {
//            console.log("UpgradeOption", this.option);
        }
    };
</script>

<style>
    .upgrade-option-item{
        background-color: #3777BB;
        text-align: center;
        padding:10px;
        margin:0;
        cursor:pointer;
        width:370px;
        height:120px;
    }
    .upgrade-option-item .points-text {
        font-family: 'ScalaSansPro';
        font-size: 52px;
        font-weight:bold;
        margin:0;
        margin-top:15px;
        color:#FFFFFF;
    }
    .upgrade-option-item .euro-text {
        font-family:'ScalaSansPro';
        font-size: 27px;
        margin:0;
        margin-top:-8px;
        color:#FFFFFF;
    }
</style>