
<template>
    <main class="app_main">
        <router-view v-slot="{ Component }">
            <TransitionPageV2>
                <component :is="Component"/>
            </TransitionPageV2>
        </router-view>
    </main>
</template>

<script>
    import TransitionPageV2 from './components/TransitionPageV2.vue';
    import {mapState} from 'vuex';
    import {mapGetters} from 'vuex';
    export default {
        name: 'App',
        components: {TransitionPageV2},
        idleTimer: null,
        computed: {
            ...mapState('ccv', ['transaction', 'transaction_status', 'transaction_polling', 'transaction_receipt_email']),
            ...mapState('kliko', ['upgrade_option']),
            ...mapGetters('ccv', ['transaction_status_code']),
            ...mapGetters('kliko', ['order_status_code'])
        },
        watch: {
        },
        mounted() {
            window.addEventListener('mousemove', this.onResetIdleTimer);
            window.addEventListener('mousedown', this.onResetIdleTimer);
            window.addEventListener('keypress', this.onResetIdleTimer);
            window.addEventListener('touchmove', this.onResetIdleTimer);

            window.addEventListener('offline', this.onInternetOffline);
            this.onResetIdleTimer();
        },
        created() {
            this.$router.beforeEach(this.onPageChange);
        },
        methods: {
            onPageIdle: function () {
                if (this.transaction_polling) {
                    this.onResetIdleTimer();
                } else {
                    this.$router.push({name: 'Home'});
                }
            },
            onResetIdleTimer: function () {
                clearTimeout(this.idleTimer);
                this.idleTimer = setTimeout(this.onPageIdle, 30000);
            },
            onPageChange: function (to, from, next) {
                this.onResetIdleTimer();
                next();
            },
            onInternetOffline: function () {
                this.$router.push({name: 'ErrorOffline'});
            }
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>
    @font-face {
        font-family: "ScalaSansPro";
        font-display: block;    
        src: local("ScalaSansPro"), url(./fonts/scalasanspro.ttf) format("truetype");
    }

    @font-face {
        font-family: "RotisSemiSansStd-Bold";
        font-display: block;     
        src: local("RotisSemiSansStd-Bold"), url(./fonts/RotisSemiSansStd-Bold.otf) format("opentype");
    }

    * {
        box-sizing: border-box;
    }

    html, body{
        margin:0;
        padding:0;
        overflow:hidden;
        height:100%;
    }
    .page-title {
        font-family:'RotisSemiSansStd-Bold';
        font-size :120px;
        color:#FFFFFF;
        text-align:center;
        margin:0;
        margin-top:380px;
    }
    .page-subtitle{
        font-family:'ScalaSansPro'; 
        font-size :70px;
        color:#FFFFFF;
        text-align:center;
        margin:0;
        margin-top:20px;
    }
    #app {
        display: flex;
        flex-direction: column;
        height:100%;        

    }
    .app_main {     
        display: flex;     
        width: 100%;
        height: 100%;  
        flex-wrap: nowrap;
        flex-direction: row;
        position:relative;
        
         background-color: #3399ff;
        background-image: url('~@/assets/default_screen.png');
        background-size:cover;
        background-position:center center;
        background-repeat: no-repeat;
    }
    
    .wrapper {
        width: 100%;
        height:100%;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
    }
    .button-yellow {
        background-color:#FFD95C;
        width:360px;
        height:120px;
        font-family:"ScalaSansPro";
        font-weight:bold;
        color:#000000;
        font-size:52px;
        text-align:center;
        border: none;
        outline:none;
    }
    .button-green {
        background-color:#63A160;
        width:360px;
        height:120px;
        font-family:"ScalaSansPro";
        font-weight:bold;
        color:#FFFFFF;
        font-size:52px;
        text-align:center;
        border: none;
        outline:none;
    }
    .button-abort {
        position:absolute;
        bottom:223px;

    }
</style>