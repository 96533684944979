<template>
    <div id="preloader-circle">

    </div>
</template>

<script>
    export default {
        props: {

        },
        mounted() {

        }
    };
</script>

<style scoped>
    #preloader-circle{
        width: 80px;
        height: 80px;

        border: 2px solid #f3f3f3;
        border-top:4px solid #FFD95C;
        border-radius: 100%;

        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right: 0;
        margin: auto;

        animation: spin 1s infinite linear;
    }

    @keyframes spin {
        from{
            transform: rotate(0deg);
        }to{
            transform: rotate(360deg);
        }
    }

</style>