<template>
    <div class="wrapper">
        <p class="page-title">Welkom!!!</p>
        <p class="page-title">Houd uw afvalpas<br>voor de paslezer<br>om te beginnen</p>
        <img class='page-arrow' src='~@/assets/arrow.png'>
    </div>
</template> 

<script>
    import {mapState} from 'vuex';
    export default {
        name: 'Home',
        computed: {
            ...mapState('kliko', ['serialnumber']),
        },
        props: {
            msg: String
        },
        watch: {
            async serialnumber(c1, c2) {
                console.log('watchdog serialnumber', c1, c2, this.isMounted);
                if (c1 !== c2 && this.isMounted) {
                    this.$router.push({name: 'Options'});
                }
            },
        },
        mounted() {
            this.isMounted = true;
            this.$store.dispatch('resetState');
            this.$store.dispatch('sitekiosk/loadModel');
        },

        beforeUnmount() {
            this.isMounted = false;
        },
        methods: {
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .page-arrow {
        margin-top:50px;
    }
</style>
