<template>
    <transition :name="transitionName">        
        <slot/>
    </transition>
</template>

<script>

    export default {
        name: 'TransitionPageV2',
        data() {
            return {
                transitionName: "slide",
                transitionEnterActiveClass: '',
            };
        },
        created() {
            this.$router.beforeEach((to, from, next) => {
                const pageList = ['Home', 'Error', 'Options', 'EmailForm', 'Payment', 'Summary'];
                if (pageList.indexOf(to.name) < pageList.indexOf(from.name)) {
                    this.transitionName = 'slide-right';
                } else {
                    this.transitionName = 'slide-left';
                }
                next();
            });
        },
        methods: {
        }
    };
</script>

<style>
    .slide-left-enter-active,
    .slide-left-leave-active,
    .slide-right-enter-active,
    .slide-right-leave-active {
        transition-duration: 0.6s;
        transition-property:  transform,opacity;
        transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);       
        position: absolute;
    }
    .slide-left-enter-to    {        transform: translate(0, 0);        opacity:1;    }
    .slide-left-enter-from  {        transform: translate(5rem, 0);    opacity:0;    }
    .slide-left-leave-to    {        transform: translate(-5rem, 0);   opacity:0;    }
    .slide-left-leave-from  {        transform: translate(0, 0);        opacity:1;   }

    .slide-right-enter-to   {        transform: translate(0, 0);        opacity:1;   }
    .slide-right-enter-from {        transform: translate(-5rem, 0);   opacity:0;   }
    .slide-right-leave-to   {        transform: translate(5rem, 0);    opacity:0;   }
    .slide-right-leave-from {        transform: translate(0, 0);        opacity:1;   }
</style>