<template>
    <div class="wrapper">     
        <div v-if="!kliko_account">
            <!--<p class="page-title">Kaart<br>wordt geladen</p>-->
            <preloader></preloader>
        </div>
        <div v-else-if="kliko_account.valid">
            <p class="page-title">Opwaarderen,<br>maak uw keuze</p>
            <div class="upgrade-option-list" >
                <upgrade-option v-for="(option,index) in kliko_account.options" :option="option" :key="index" @click="onOptionSelect(index)"></upgrade-option>
            </div>        
            <button class="button-yellow button-abort" v-on:click="onAbortClick">Afbreken</button>
            <p class="saldo-title">Huidige saldo:</p>
            <p class="saldo-value">{{ parseInt(this.kliko_account_balance) }} {{this.kliko_account_balance>1?'punten':'punt'}}</p>
        </div>
        <div v-else-if="!kliko_account.valid">
            <p class="page-title">Kaart<br>niet gevonden</p>
            <button class="button-yellow button-abort" v-on:click="onAbortClick">Afbreken</button>
        </div>
    </div>
</template>

<script>
    import UpgradeOption from './../components/UpgradeOption.vue';
    import Preloader from './../components/Preloader.vue';
    import {mapState} from 'vuex';
    import {mapGetters} from 'vuex';
    export default {
        name: 'Options',
        preloadTimeout: null,
        returnTimeout: null,
        components: {UpgradeOption, Preloader},
        computed: {
            ...mapState('kliko', ['kliko_account', 'serialnumber']),
            ...mapGetters('kliko', ['kliko_account_balance'])
        },
        async mounted() {
            this.preloadTimeout = setTimeout(async () => {
                await this.$store.dispatch('kliko/loadCard', this.serialnumber);
                if (!this.kliko_account.valid) {
                    this.returnTimeout = setTimeout(() => {
                        this.$router.push({name: 'Home'});
                    }, 5000);
                }
            }, 500);
        },
        unmounted() {
            clearTimeout(this.preloadTimeout);
            clearTimeout(this.returnTimeout);
        },
        methods: {
            onOptionSelect: function (index) {
                let option = this.kliko_account.options[index];
                this.$store.commit('kliko/SET_UPGRADE_OPTION', option);
                this.$router.push({name: 'EmailForm'});
            },
            onAbortClick: function () {
                this.$router.push({name: 'Home'});
            }
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .wrapper :first-child {
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .upgrade-option-list{
        display:grid;
        grid-gap:30px;
        grid-template-columns:repeat(2,1fr);
        margin-top:140px;
    }
    .saldo-title {
        font-family:'ScalaSansPro';
        font-size: 70px;
        font-weight: bold;
        color:#ffffff;
        margin:0;
        margin-top:130px;
    }
    .saldo-value {
        font-family:'RotisSemiSansStd-Bold';
        font-size :170px;
        color:#ffffff;
        margin:0;
    }

</style>
