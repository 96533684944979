import { createApp } from 'vue';

import App from './App.vue';
import router from './Router.js';
import Store from './Store.js';

import axios from 'axios';
//import VueAxios from 'vue-axios';

axios.defaults.withCredentials = true;
createApp(App).use(router).use(Store).mount('#app');

