
import KlikoService from './../services/KlikoService';
import router from './../Router.js';

const getDefaultState = () => {
    return {
    };
}

const CCVModule = {
    namespaced:true,
    state: getDefaultState(),
    getters: {
        transaction_status_code: (state,getters,rootState) => {
            if (state.transaction) {
                if (state.transaction.type == 'sale') {
                    if (state.transaction_status) {
                        return state.transaction_status.status;
                    }
                } else if (state.transaction.type == 'process_error') {
                    return 'process_error';
                }
            } else if (rootState.kliko.upgrade_option) {
                return 'starting';
            } else {
                return 'unknown';
            }
        },
    },
    mutations: {

        SET_TRANSACTION(state, response) {
            console.log('CCV:SET_TRANSACTION', response);
            state.transaction = response;
        },
        SET_TRANSACTION_POLLING(state, response) {
            console.log('CCV:SET_TRANSACTION_POLLING', response);
            state.transaction_polling = response;
        },
        SET_TRANSACTION_STATUS(state, response) {
            console.log('CCV:SET_TRANSACTION_STATUS', response);
            state.transaction_status = response;
        },
        SET_TRANSACTION_RECEIPT_EMAIL(state, response) {
            console.log('CCV:SET_TRANSACTION_RECEIPT_EMAIL', response);
            state.transaction_receipt_email = response;
        },
    },
    actions: {
        async startPayment( {commit, state, rootState, dispatch}){
            try {
                commit("SET_TRANSACTION", null);
                commit("SET_TRANSACTION_STATUS", null);
                const response = await KlikoService.startPayment(Date.now(), rootState.kliko.serialnumber, rootState.kliko.upgrade_option.euro);
                commit("SET_TRANSACTION", response.data);
                if (response.data.type == "sale") {
                    dispatch('startPaymentStatusPolling');
                } else if (response.data.type == "process_error") {
                    state.transaction_retry_timer = setTimeout(() => {
                        dispatch('startPayment');
                    }, 2000);
                }

            } catch (error) {
                if (error.response) {
                    router.push({name: 'Error', params: {status_code: error.response.status, error_code: error.response.data.error, error_message: error.response.data.message}});
                } else {
                    router.push({name: 'Error', params: {status_code: error.status, error_code: 'Onbekend', error_message: 'Kliko onbereikbaar'}});
                }
        }
        },
        async startPaymentStatusPolling( {commit, state, dispatch}) {
            commit("SET_TRANSACTION_POLLING", true);
            await dispatch('getPaymentStatus');
            state.transaction_polling_timer = setTimeout(() => {
                if (state.transaction_polling) {
                    dispatch('startPaymentStatusPolling');
                }
            }, 2000);
        },
        async stopPayment( {commit, state}) {
            console.log('stopPayment', state.transaction_polling_timer);
            commit("SET_TRANSACTION_POLLING", false);
            clearTimeout(state.transaction_polling_timer);
            clearTimeout(state.transaction_retry_timer);

        },
        async getPaymentStatus( {commit, state, dispatch}) {
            try {
                const transaction_response = await KlikoService.getPaymentStatus(state.transaction.reference);
                commit("SET_TRANSACTION_STATUS", transaction_response.data);
                if (transaction_response.data.status != 'pending' && transaction_response.data.status != 'manualintervention') {
                    dispatch('stopPayment');
                }
            } catch (error) {
               if (error.response) {
                    router.push({name: 'Error', params: {status_code: error.response.status, error_code: error.response.data.error, error_message: error.response.data.message}});
                } else {
                    router.push({name: 'Error', params: {status_code: error.status, error_code: 'Onbekend', error_message: 'Kliko onbereikbaar'}});
                }
        }
        },
        async sendReceipt( { state,rootState}){
            await KlikoService.sendReceipt(
                    state.transaction.reference,
                    rootState.kliko.serialnumber,
                    rootState.kliko.upgrade_option.balance,
                    state.transaction_receipt_email,
                    state.transaction_status.details.customerReceipt
                    );
        }
    }
};

export default CCVModule
    