import { createWebHistory, createRouter }
from "vue-router";
import Store from './Store.js';
import Home from "@/views/Home.vue";
import Options from "@/views/Options.vue";
import Payment from "@/views/Payment.vue";
import PaymentReturn from "@/views/PaymentReturn.vue";
import Summary from "@/views/Summary.vue";
import EmailForm from "@/views/EmailForm.vue";
import Error from "@/views/Error.vue";
import ErrorOffline from "@/views/ErrorOffline.vue";
//import PageNotFound from "@/views/PageNotFound.vue";
const routes = [
    {path: "/", name: "Home", component: Home, },
    {path: "/options", name: "Options", component: Options, props: true, },
    {path: "/payment", name: "Payment", component: Payment, props: true, },
    {path: "/paymentReturn", name: "PaymentReturn", component: PaymentReturn, },
    {path: "/summary", name: "Summary", component: Summary, },
    {path: "/error", name: "Error", component: Error, props: true, },
    {path: "/emailform", name: "EmailForm", component: EmailForm,},
    {path:"/errorOffline",name:"ErrorOffline",component:ErrorOffline},
    {path: '/:catchAll(.*)*', redirect: '/', }, 
];
const router = createRouter({
    mode: "history",
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    
    if (to.name == 'Options' && !Store.state.kliko.serialnumber) {
        next('Home');
    } else if (to.name == 'EmailForm' && !Store.state.kliko.upgrade_option && !Store.state.kliko.serialnumber) {
        next('Home');
    } else if (to.name == 'Payment' && !Store.state.kliko.upgrade_option && !Store.state.kliko.serialnumber) {
        next('Home');
    } else if (to.name == 'Summary' && !Store.state.kliko.kliko_account) {
        next('Home');
    } else {
        next();
    }
});
export default router