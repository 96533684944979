const url = "http://kliko.omnivision.nl/api/kliko/"

import axios from 'axios';
//import VueAxios from 'vue-axios';
axios.defaults.withCredentials = true;
axios.defaults.credentials = true;
class KlikoService {
    
    static async getBalance(serialnumber) {
        console.log('KlikoService:getBalance', serialnumber);
        return axios.post(url + "getBalance", {"serialnumber": serialnumber});
    }

    static async updateBalance(reference, serialnumber, amount) {
        console.log('KlikoService:updateBalance', reference, serialnumber, amount);
        return axios.post(url + "updateBalance", {"reference": reference, "serialnumber": serialnumber, "amount": amount});
    }

    static async startPayment(reference, serialnumber, amount) {
        console.log('KlikoService:startPayment', reference, serialnumber, amount);
        return axios.post(url + "startPayment", {"reference": reference, "serialnumber": serialnumber, "amount": amount});
    }

    static async getPaymentStatus(reference) {
        console.log('KlikoService:getPaymentStatus', reference);
        return axios.post(url + "getPaymentStatus", {"reference": reference});
    }
    
    static async sendReceipt(reference, serialnumber, amount, email, receipt) {
        console.log('KlikoService:sendReceipt', reference);
        return axios.post(url + "sendReceipt", {"reference": reference, "serialnumber": serialnumber, "amount": amount, "email": email, "receipt": receipt});
    }

}


export default KlikoService;