<template>
    <div class="wrapper">
        <p class="page-title">Bedankt voor <br>uw aankoop!</p>
        <p class='saldo-title'>Nieuwe Saldo:</p>
        <p class="saldo-value">{{ parseInt(this.order_status.newBalance) }} {{this.order_status.newBalance>1?'punten':'punt'}}</p>
        <!--<p class='kliko-msg'>{{kliko_account.text}}</p>-->
        <button class="button-yellow button-abort" v-on:click="onCloseSessionClick">Sessie afsluiten</button>
        <p class='timer-msg'>(Na 5 seconden keert u automatisch terug naar het HOME scherm)</p>

    </div>
</template>

<script>
    import {mapState} from 'vuex';
    export default {
        name: 'Summary',
        closeTimer: null,
        props: {

        },
        computed: {
            ...mapState('kliko', ['order_status']),
            ...mapState('ccv', ['transaction_receipt_email'])
        },
        mounted() {
            this.closeTimer = setTimeout(this.onCloseSessionClick, 10000);
        },
        unmounted() {
            clearTimeout(this.closeTimer);
        },
        methods: {
            onCloseSessionClick: function () {
                this.$router.push({name: 'Home'});
            },
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .wrapper :first-child {
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .saldo-title,
    .kliko-msg {
        font-family:'ScalaSansPro';
        font-size: 70px;
        font-weight: bold;
        color:#ffffff;
        margin:0;
        margin-top:260px;
    }
    .kliko-msg {
        margin-top:50px;
    }
    .saldo-value {
        font-family:'ScalaSansPro';
        font-size :170px;
        color:#ffffff;
        margin:0;
        /*margin-top:80px;*/
    }
    .timer-msg {
        position:absolute;
        bottom:170px;
        font-family:'ScalaSansPro';
        font-size :27px;
        color:#ffffff;
        margin:0;
    }
    .button-abort {
        width:470px;
    }
</style>
