<template>
    <div class="wrapper">
        <p class="page-title">Opwaarderen, <br>uw keuze</p>
        <upgrade-option :option="upgrade_option"></upgrade-option>
        <div id="payment-wrapper" ref="paymentWrapper" v-if="transaction">
            <iframe :src="transaction.payUrl" ref="ccvframe"></iframe>
        </div>
        <div class="transaction-status">            
            <p v-if="transaction_status_code == 'starting'">Een moment geduld, <br>de terminal wordt geactiveerd.</p>
            <p v-else-if="transaction_status_code == 'pending'">U kunt nu betalen<br>op de PIN terminal.</p>
            <p v-else-if="transaction_status_code == 'success'">Betaling ontvangen</p>
            <p v-else-if="transaction_status_code == 'manualintervention'">Betalingsstatus onbekend, <br>waarschuw het personeel.</p>
            <p v-else-if="transaction_status_code == 'unknown'">Een moment geduld, <br>de terminal wordt geactiveerd.</p>
            <div v-else-if="transaction_status_code == 'process_error'">
                <p>Pin terminal in gebruik, <br>even geduld a.u.b.</p>
            </div>            
            <div v-else-if="transaction_status_code == 'failed'">Betaling mislukt<br>of geannuleerd</div>   
            <div v-else-if="order_status_code == 'error'">Opwaardering mislukt<br>waarschuw het personeel.</div>   
        </div>        
        <!--<button class="button-yellow button-abort" v-on:click="onAbortClick" v-if="transaction_status_code !== 'success'">Afbreken</button>-->
    </div>
</template>

<script>
    import UpgradeOption from './../components/UpgradeOption.vue';
    import {mapState} from 'vuex';
    import {mapGetters} from 'vuex';
    export default {
        name: 'Payment',
        components: {UpgradeOption},
        computed: {
            ...mapState('ccv', ['transaction', 'transaction_status', 'upgrade_option', 'transaction_receipt_email']),
            ...mapState('kliko', ['upgrade_option']),
            ...mapGetters('ccv', ['transaction_status_code']),
            ...mapGetters('kliko', ['order_status_code'])
        },
        watch: {
            async transaction_status_code(code) {
                console.log('watchdog transaction_status_code', code);
                switch (code) {
                    case 'success':
                        this.$store.dispatch('kliko/updateBalance');
                        break;
                    case 'failed':
                        setTimeout(() => {
                            this.$router.push({name: 'Options'});
                        }, 2000);
                        break;
                }
            },
            async order_status_code(code) {
                console.log('watchdog order_status_code', code);
                switch (code) {
                    case "success":
                        if (this.transaction_receipt_email && this.transaction_receipt_email.length > 1) {
                            this.$store.dispatch('ccv/sendReceipt');
                        }
                        this.$router.push({name: 'Summary'});
                        break;
                        
                }
            }

        },
        async mounted() {
            this.$store.dispatch('ccv/startPayment');
        },
        unmounted() {
            this.$store.dispatch('ccv/stopPayment');
        },
        methods: {
            onTryAgainClick: function () {
                this.$store.dispatch('ccv/startPayment');
            },
//            onAbortClick: function () {
//                this.$router.push({name: 'Options'});
//            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .wrapper :first-child {
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .upgrade-option-item {
        margin-top:140px; 
    }
    .transaction-status{
        font-family:'ScalaSansPro';
        font-size: 70px;
        font-weight: bold;
        color:#ffffff;
        margin:0;
        margin-top:20px;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align:center;
    }
    #payment-wrapper {
        width:1px;
        height:1px;
        display:none;
        position:absolute;
        left:-1px;
        top:-1px;
        margin:0px;
        padding:0px;
    }

</style>